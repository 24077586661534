import React from 'react';

import '../style/main.scss';

const NotFoundPage = () => (
    <div className="error404">
        <h2 className="section-heading text-uppercase">Too deep?!</h2>
        <p className="text-center">
            404! Sorry, the page you are looking for, probably cannot swim.
            <br/>
            To ascend back to the surface <a href="/">click here!</a>
        </p>
    </div>
);

export default NotFoundPage;
